import { debounce } from "@finbackoffice/site-core";
import { useState, useEffect } from "react";

export const useScreenWidth = () => {
    const [screenWidth, setScreenWidth] = useState(
        typeof window !== "undefined" ? window.innerWidth : 0,
    );

    useEffect(() => {
        const handleResize = debounce(() => {
            setScreenWidth(window.innerWidth);
        }, 200);

        if (typeof window !== "undefined") {
            window.addEventListener("resize", handleResize);
        }

        return () => {
            if (typeof window !== "undefined") {
                window.removeEventListener("resize", handleResize);
            }
        };
    }, []);

    return screenWidth;
};
